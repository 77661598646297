import React from "react";
import {useTranslation} from 'react-i18next'
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

import AuthService from "../../services/auth.service";
import ErrorHandler from "../../services/error.handler";

import SuperLoader from "../../components/SuperLoader";
import CustifySwitch from "../../components/CustifySwitch";
import {backgroundMain} from "../../components/Colors";
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Alert from "@mui/material/Alert";

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import PersonIcon from '@mui/icons-material/Person';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import {styled} from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import Stack from "@mui/material/Stack";
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableSortLabel from "@mui/material/TableSortLabel";
import DeleteIcon from '@mui/icons-material/Delete';

const UserList = () => {

    const {t} = useTranslation();
    const [userList, setUserList] = useState([]);
    const [checked, setChecked] = useState(['wifi']);
    const [loading, setLoading] = useState(false);
    const [dense, setDense] = useState(false);
    const [listFilter, setListFilter] = useState("all");
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("error");
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('email');

    const Demo = styled('div')(({theme}) => ({
        backgroundColor: theme.palette.background.paper,
        // backgroundColor: "red",
    }));

    const navigate = useNavigate();

    useEffect(() => {
        loadUserList();
    }, []);

    const loadUserList = async () => {
        setLoading(true);
        await AuthService.getUserList()
            .then(function (response) {
                // handle success
                setLoading(false);
                setUserList(response?.data);
            })
            .catch((error) => {
                // expired access token
                console.log(error.response.status);
                console.log(error.response.data.code);
                console.log("Access token has expired. Refreshing...");
                if (error.response.status === 401 && error.response.data.code === "token_not_valid") {
                    AuthService.refreshAccessToken()
                        .then(() => {
                            loadUserList();
                        })
                        .catch((error) => {
                            // expired refresh token => logout
                            console.log(error.response.status);
                            console.log(error.response.data.code);
                            console.log("Refresh token has expired. Logout...");
                            if (error.response.status === 401 && error.response.data.code === "token_not_valid") {
                                AuthService.logout();
                                // navigate('/');
                                window.location.reload();
                            }
                        });
                } else if (error.response.status === 403) {
                    navigate('/');
                    window.location.reload();
                }
            })
    };

    const handleError = (error) => {
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            // console.log(error.response.headers);
            console.log("Access token has expired. Refreshing...");
            if (error.response.status === 401 && error.response.data.code === "token_not_valid") {
                AuthService.refreshAccessToken()
                    .then(() => {
                        console.log("Starting the main function again...activateDeactivateUser");
                        activateDeactivateUser(user);
                    })
                    .catch((error) => {
                        // expired refresh token => logout
                        ErrorHandler.handleError(error);
                        console.log("Refresh token has expired. Logout...");
                        if (error.response.status === 401 && error.response.data.code === "token_not_valid") {
                            AuthService.logout();
                            // navigate('/');
                            window.location.reload();
                        }
                    });
            } else if (error.response.status === 403) {
                navigate('/');
                window.location.reload();
            }
        } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
            setMessage('Error: ' + error.message);
            setLoading(false);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
            setMessage('Error' + error.message);
            setLoading(false);
        }
        console.log(error.config);
    }

    const activateDeactivateUser = async (user) => {
        setLoading(true);
        AuthService.isAccessTokenStillAlive()
            .then(function (response) {
                // console.log(response);
                if (user.is_active) {
                    userDeactivator(user);
                } else {
                    userActivator(user);
                }
            })
            .catch((error) => {
                handleError(error);
            });
    };
    const activateDeactivateAdmin = async (user) => {
        setLoading(true);
        AuthService.isAccessTokenStillAlive()
            .then(function (response) {
                // console.log(response);
                if (user.is_active) {
                    userDeactivator(user);
                } else {
                    userActivator(user);
                }
            })
            .catch((error) => {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log(error.response.data);
                    console.log(error.response.status);
                    // console.log(error.response.headers);
                    console.log("Access token has expired. Refreshing...");
                    if (error.response.status === 401 && error.response.data.code === "token_not_valid") {
                        AuthService.refreshAccessToken()
                            .then(() => {
                                console.log("Starting the main function again...activateDeactivateUser");
                                activateDeactivateUser(user);
                            })
                            .catch((error) => {
                                // expired refresh token => logout
                                ErrorHandler.handleError(error);
                                console.log("Refresh token has expired. Logout...");
                                if (error.response.status === 401 && error.response.data.code === "token_not_valid") {
                                    AuthService.logout();
                                    // navigate('/');
                                    window.location.reload();
                                }
                            });
                    } else if (error.response.status === 403) {
                        navigate('/');
                        window.location.reload();
                    }
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.log(error.request);
                    setMessage('Error: ' + error.message);
                    setLoading(false);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                    setMessage('Error' + error.message);
                    setLoading(false);
                }
                console.log(error.config);
            });
    };
    const activateDeactivateSettleUp = async (user) => {
        setLoading(true);
        AuthService.isAccessTokenStillAlive()
            .then(function (response) {
                // console.log(response);
                if (user.is_active) {
                    userDeactivator(user);
                } else {
                    userActivator(user);
                }
            })
            .catch((error) => {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log(error.response.data);
                    console.log(error.response.status);
                    // console.log(error.response.headers);
                    console.log("Access token has expired. Refreshing...");
                    if (error.response.status === 401 && error.response.data.code === "token_not_valid") {
                        AuthService.refreshAccessToken()
                            .then(() => {
                                console.log("Starting the main function again...activateDeactivateUser");
                                activateDeactivateUser(user);
                            })
                            .catch((error) => {
                                // expired refresh token => logout
                                ErrorHandler.handleError(error);
                                console.log("Refresh token has expired. Logout...");
                                if (error.response.status === 401 && error.response.data.code === "token_not_valid") {
                                    AuthService.logout();
                                    // navigate('/');
                                    window.location.reload();
                                }
                            });
                    } else if (error.response.status === 403) {
                        navigate('/');
                        window.location.reload();
                    }
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.log(error.request);
                    setMessage('Error: ' + error.message);
                    setLoading(false);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                    setMessage('Error' + error.message);
                    setLoading(false);
                }
                console.log(error.config);
            });
    };
    const userActivator = (user) => {
        AuthService.getUidAndTokenToActivateUser(user.id)
            .then(function (response) {
                console.log(response.data);
                AuthService.activateUser(response.data)
                    .then((response) => {
                        console.log(response.data);
                        AuthService.requirePasswordResetLink(user.email)
                            .then((response) => {
                                console.log(response.data);
                                setUserList((prevState) => {
                                    return prevState.map((el) => {
                                        if (el.id === user.id) {
                                            return {
                                                ...el,
                                                is_active: true,
                                            };
                                        } else {
                                            return el;
                                        }
                                    });
                                });
                                setLoading(false);
                            })
                            .catch(function (error) {
                                // handle error requirePasswordResetLink
                                //todo
                                console.log(error);
                            })
                            .then(function () {
                                // always executed
                                // todo
                            });
                    })
                    .catch(function (error) {
                        // handle error activateUser
                        //todo
                        console.log(error);
                    })
                    .then(function () {
                        // always executed
                        // todo
                    });
            })
            .catch(function (error) {
                // handle error
                //todo
                console.log(error);
            })
            .then(function () {
                // always executed
                // todo
            });
    }
    const userDeactivator = (user) => {
        if (user.is_active) {
            AuthService.getUidAndTokenToActivateUser(user.id)
                .then(function (response) {
                    console.log(response.data);
                    AuthService.deActivateUser(response.data)
                        .then(function (response) {
                            console.log(response.data);
                            setUserList((prevState) => {
                                return prevState.map((el) => {
                                    if (el.id === user.id) {
                                        return {
                                            ...el,
                                            is_active: false,
                                        };
                                    } else {
                                        return el;
                                    }
                                });
                            });
                        })
                })
                .catch()
            setLoading(false);
            return true;
        }
    }
    const adminDeactivator = (user) => {
        if (user.is_active) {
            AuthService.getUidAndTokenToActivateUser(user.id)
                .then(function (response) {
                    console.log(response.data);
                    AuthService.deActivateAdmin(response.data)
                        .then(function (response) {
                            console.log(response.data);
                            setUserList((prevState) => {
                                return prevState.map((el) => {
                                    if (el.id === user.id) {
                                        return {
                                            ...el,
                                            is_admin: false,
                                        };
                                    } else {
                                        return el;
                                    }
                                });
                            });
                        })
                })
                .catch()
            setLoading(false);
            return true;
        }
    }

    const activateDeactivateInvoice = async (user) => {
        setLoading(true);
        AuthService.isAccessTokenStillAlive()
            .then(function (response) {
                // console.log(response);
                if (user.to_invoice) {
                    invoiceDeactivator(user);
                } else {
                    invoiceActivator(user);
                }
            })
            .catch((error) => {
                handleError(error);
            });
    };
    const invoiceActivator = (user) => {
        AuthService.getUidAndTokenToActivateUser(user.id)
            .then(function (response) {
                console.log(response.data);
                AuthService.activateInvoice(response.data)
                    .then((response) => {
                        console.log(response.data);
                        setUserList((prevState) => {
                            return prevState.map((el) => {
                                if (el.id === user.id) {
                                    return {
                                        ...el,
                                        to_invoice: true,
                                    };
                                } else {
                                    return el;
                                }
                            });
                        });
                        setLoading(false);
                    })
                    .catch(function (error) {
                        // handle error requirePasswordResetLink
                        //todo
                        console.log(error);
                    })

            })
            .catch(function (error) {
                // handle error activateUser
                //todo
                console.log(error);
            })
    }
    const invoiceDeactivator = (user) => {
        if (user.is_active) {
            AuthService.getUidAndTokenToActivateUser(user.id)
                .then(function (response) {
                    console.log(response.data);
                    AuthService.deActivateInvoice(response.data)
                        .then(function (response) {
                            console.log(response.data);
                            setUserList((prevState) => {
                                return prevState.map((el) => {
                                    if (el.id === user.id) {
                                        return {
                                            ...el,
                                            to_invoice: false,
                                        };
                                    } else {
                                        return el;
                                    }
                                });
                            });
                        })
                })
                .catch()
            setLoading(false);
            return true;
        }
    }

    const handleRadioFilterChange = (event) => {
        setListFilter(event.target.value)
    }

    const handleStackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setMessage(undefined);
    };

    // function user(user) {

    //     if (listFilter === "only_active" && !user.is_active) {
    //         return null;
    //     } else if (listFilter === "only_inactive" && user.is_active) {
    //         return null;
    //     }
    //     return (
    //         <ListItem>
    //             <ListItemAvatar>
    //                 <Avatar>
    //                     <PersonIcon/>
    //                 </Avatar>
    //             </ListItemAvatar>
    //             <ListItemText
    //                 primary={user.email}
    //                 secondary={
    //                     <React.Fragment>
    //                         <Typography
    //                             sx={{display: 'inline'}}
    //                             component="span"
    //                             variant="body2"
    //                             color="text.primary"
    //                         >
    //                             {user.name}
    //                         </Typography>
    //                         {user.is_admin &&
    //                         <strong> - {t('admin')}</strong>
    //                         }
    //                         {!user.is_admin &&
    //                         <> - {t('user')}</>
    //                         }
    //                         {user.coupon !== '' &&
    //                         <strong style={{color: 'green'}}> / Coupon: {user.coupon}</strong>
    //                         }
    //                     </React.Fragment>
    //                 }
    //             />
    //             {t('active')}
    //             <CustifySwitch
    //                 edge="end"
    //                 onChange={() => activateDeactivateUser(user)}
    //                 checked={user.is_active}
    //                 disabled={user.is_admin}
    //                 inputProps={{
    //                     'aria-labelledby': 'switch-list-label-wifi',
    //                 }}
    //             />
    //             {t('delete')}
    //              <CustifySwitch
    //                 edge="end"
    //                 onChange={() => activateDeactivateUser(user)}
    //                 checked={user.is_active}
    //                 disabled={user.is_admin}
    //                 inputProps={{
    //                     'aria-labelledby': 'switch-list-label-wifi',
    //                 }}
    //             />
    //             {t('to_invoice')}
    //              <CustifySwitch
    //                 edge="end"
    //                 onChange={() => activateDeactivateUser(user)}
    //                 checked={user.is_active}
    //                 disabled={user.is_admin}
    //                 inputProps={{
    //                     'aria-labelledby': 'switch-list-label-wifi',
    //                 }}
    //             />
    //             {t('make admin')}
    //              <CustifySwitch
    //                 edge="end"
    //                 onChange={() => activateDeactivateUser(user)}
    //                 checked={user.is_active}
    //                 disabled={user.is_admin}
    //                 inputProps={{
    //                     'aria-labelledby': 'switch-list-label-wifi',
    //                 }}
    //             />
    //         </ListItem>
    //     )
    // }
    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const sortedUsers = userList.sort((a, b) => {
        if (orderBy === 'email') {
            return (order === 'asc' ? a.email.localeCompare(b.email) : b.email.localeCompare(a.email));
        }
        return 0;
    });

    const renderTable = () => {
        if (listFilter === "only_active" && !user.is_active) {
            return null;
        } else if (listFilter === "only_inactive" && user.is_active) {
            return null;
        }
        return (
            <Table aria-label="simple table" size="large">
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <TableSortLabel
                                active={orderBy === 'email'}
                                direction={orderBy === 'email' ? order : 'asc'}
                                onClick={() => handleRequestSort('email')}
                            >
                                Email
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>Aktiv</TableCell>
                        <TableCell align="right">Abrechnen</TableCell>
                        <TableCell align="right">Admin</TableCell>
                        <TableCell align="right">Delete</TableCell>

                    </TableRow>
                </TableHead>
                <TableBody>
                    {sortedUsers.map((user) => (
                        <TableRow
                            // key={row.name}
                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                        >
                            <TableCell>
                                <ListItemText
                                    primary={user.email}
                                    secondary={
                                        <React.Fragment>
                                            <Typography
                                                sx={{ display: 'inline' }}
                                                component="span"
                                                variant="body2"
                                                color="text.primary"
                                            >
                                                {user.name}
                                            </Typography>
                                            {user.is_admin &&
                                                <strong> - {t('admin')}</strong>
                                            }
                                            {!user.is_admin &&
                                                <> - {t('user')}</>
                                            }
                                            {user.coupon !== '' &&
                                                <strong style={{ color: 'green' }}> / Coupon: {user.coupon}</strong>
                                            }
                                        </React.Fragment>
                                    }
                                /></TableCell>
                            <TableCell>
                                <CustifySwitch
                                    edge="end"
                                    onChange={() => activateDeactivateUser(user)}
                                    checked={user.is_active}
                                    disabled={user.is_admin}
                                    inputProps={{
                                        'aria-labelledby': 'switch-list-label-wifi',
                                    }}
                                />
                            </TableCell>
                            <TableCell align="right"> 
                                <CustifySwitch
                                edge="end"
                                onChange={() => activateDeactivateSettleUp(user)}
                                checked={user.to_invoice}
                                disabled={user.is_admin}
                                inputProps={{
                                    'aria-labelledby': 'switch-list-label-wifi',
                                }}
                            /></TableCell>
                            <TableCell align="right"> <CustifySwitch
                                edge="end"
                                onChange={() => activateDeactivateAdmin(user)}
                                checked={user.is_admin}
                                disabled={user.is_admin}
                                inputProps={{
                                    'aria-labelledby': 'switch-list-label-wifi',
                                }}
                            /></TableCell>
                            <TableCell align="right"> 
                                {/* <CustifySwitch
                                edge="end"
                                onChange={() => activateDeactivateUser(user)}
                                checked={user.is_active}
                                disabled={user.is_admin}
                                inputProps={{
                                    'aria-labelledby': 'switch-list-label-wifi',
                                }}
                            /> */}
                           <DeleteIcon></DeleteIcon></TableCell>
                        </TableRow>
                    ))}

                </TableBody>
            </Table>
        )
    }

    return (
        <Container component="main" maxWidth="md">
            
            <Grid item xs={10} md={10}>
                {message && (
                    <Stack sx={{width: '100%', marginTop: 4.4}} spacing={2}>
                        <Alert
                            onClose={handleStackClose}
                            severity={severity}>{message}</Alert>
                    </Stack>
                )}
                {!loading && !message &&
                <Typography sx={{mt: 3.4, mb: 3, fontWeight: "bold", color: "#02615D"}} variant="h5" component="div"
                            textAlign={"center"}>
                    <PeopleOutlineIcon
                        sx={{
                            marginBottom: "-5px",
                            fontSize: "30px",
                        }}
                    />
                    &nbsp;{t('userList')}
                </Typography>
                }
                {loading && <SuperLoader/>}
                <FormControl component="fieldset"
                             style={{display: "flex", paddingLeft: "25%"}}
                >
                    
                    <RadioGroup
                        style={{textAlign: "end"}}
                        row
                        // textAlign={"center"}
                        name="filter_active"
                        value={listFilter}
                        onChange={handleRadioFilterChange}
                    >
                        <FormControlLabel value="all" control={<Radio/>} label={t('showAll')}/>
                        <FormControlLabel value="only_active" control={<Radio/>} label={t('showActive')}/>
                        <FormControlLabel value="only_inactive" control={<Radio/>} label={t('showInactive')}/>
                    </RadioGroup>
                </FormControl>
                <Demo>
                {renderTable()}
                    {/* <List
                        sx={{
                            backgroundColor: backgroundMain,
                        }}
                        dense={dense}>
                        {userList.map(user)}
                    </List> */}
                </Demo>
            </Grid>
        </Container>

    );
};

export default UserList;
